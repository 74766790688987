<template>
  <div  class="top-block">
    <div class="title">{{ title }}</div>
    <div class="number-info" :class="{ up: status === 'up', down: status === 'down' }">
      <span v-if="this.status === 'up'" style="width: 0;
            border-bottom: 5px solid #4bba41;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;margin-bottom: 3px;display:inline-block" ></span>
      <span v-if="this.status === 'down'" style="width: 0;
            border-top: 5px solid red;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;margin-bottom: 3px;display:inline-block" ></span>
      {{ getFixed(status_value,2) }}
    </div>
    <div class="bottom-info">
      <span class="value">{{ getSymbolString(value1,2) }}</span>
      <span class="value">{{ getPrecent(value2,2) }}</span>
    </div>
  </div>
</template>
<script>
import InfoCommen from "../../../mixins/InfoCommen";
export default {
  name: "topBlock",
  mixins: [InfoCommen],
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      status: this.info.status || "",
      title: this.info.title || "",
      value1: this.info.value1 || "",
      value2: this.info.value2 || "",
      status_value: this.info.status_value || "",
      selected: this.info.selected || false
    };
  },
  mounted() {
    // console.log(this.info.status);
  },
};
</script>
<style lang="less" scoped>
.top-block {
  background-color: transparent;
  text-align: center;
  line-height: 1.8rem;
  margin: 20px 0;
  .title{
    font-size: 1rem;
  }
  .number-info{
    font-size: 0.9rem;
  }

  .up {
    color: #4bba41;
  }
  .down {
    color: #ff2c2c;

  }
  .bottom-info {
    .value {
      font-size: 0.8rem;
      padding: 0 5px;
    }
  }
}
</style>
