// import request from '../utils/request'

// 没有
import request from "../utils/request";
import {setToken} from "../utils/token";

const CODE_USERD = Symbol('CODE_USERD')
window[CODE_USERD] = {}

export function toAuth() {
    const next = encodeURIComponent(window.location.href);
    const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxee3e4a6271062047&redirect_uri=${next}&response_type=code&scope=snsapi_base#wechat_redirect`
    window.location.href = url;
}

export function loginWithCode(code){
    return  request({
        method: 'get',
        url: '/api/v1/user/wxmplogin',
        params: {
            code
        }
    })
}



function getUrlParams(name) {
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"); //定义正则表达式
    const r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}

async function sleep(s){
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(true)
        },s * 1000)
    })
}





export function login(){
    // eslint-disable-next-line no-async-promise-executor
    return new Promise( async (resolve)=>{
        if(window.location.href.includes('code')){
            const code = getUrlParams('code');
            if(window[CODE_USERD][code]){
                await sleep(1)
                resolve()
                return
            }
            window[CODE_USERD][code] = true
            const token = await  loginWithCode(code)
            if (token.code != 0){
                setToken("-1")
            }else{
                setToken(token.data.Token)
            }

            resolve(true)
        }else{
            // setToken("-1")
            toAuth()
            return
        }
    })
}
