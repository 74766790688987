<template>
  <div style='position: relative;min-height: 835px;'>
    <div class="home" style="position: relative;padding-bottom: 10%">
      <van-search
          clearable
          left-icon="search"
          input-align="center"
          placeholder="请输入交易对名词/全拼/字母"
          v-model="search"
          input-class="search-input"
      />

      <van-swipe class="my-swipe" indicator-color="#ddd" :initial-swipe="swipe_index">
        <van-swipe-item v-for="(swiper_child, s_index) in swiper_list" v-bind:key="s_index">
          <div class="top-wrap">
            <div
                class="top-item"
                :class="{selected: item.selected}"
                v-for="(item, i_index) in swiper_child"
                v-bind:key="i_index"
                @click="handlerChangeList(s_index * 3 + i_index,s_index,i_index)"
            >
              <top-block :info="item"></top-block>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
      <div>
        <div v-if="this.is_qushi" class="table">
          <div class="row header">
            <div class="col col-1">交易对</div>
            <div class="col">最新价</div>
            <div class="col">当前收益</div>
            <div class="col">当前收益率</div>
          </div>

          <div
              @click="handlerGoDetail(item)"
              v-for="item in show_table_list"
              v-bind:key="item.name"
              class="row value"
          >
            <div class="col col-1">
              <div class="name">{{ item.name }}</div>
              <div class="sub-name">{{ item.sub_name }}</div>
            </div>
            <div class="col">{{ getFixed(item.new_price, 2) }}</div>
            <div class="col">{{ getSymbolString(item.profit, 2) }}</div>
            <div class="col">
          <span
              class="label"
              :class="{ 'red-bg': item.rate < 0, 'green-bg': item.rate > 0 }"
          >{{ getPrecent(item.rate, 2) }}</span>
            </div>
          </div>
        </div>
        <div v-else class="table">
          <div class="row header">
            <div class="col col-1">策略名称</div>
            <div class="col">当前收益</div>
            <div class="col">当前收益率</div>
            <div class="col">累计收益率</div>
          </div>
          <div
              v-for="item in this.grid_item"
              v-bind:key="item.name"
              class="row value"
              @click="handlerGridDetail(item.strategy_id,item.currency_names.length > 0 ? item.currency_names[0]: '',item.account_id,item.state)"
          >
            <div class="col col-1" style="display: flex">

              <div class="grid_name" style="padding-left: 0.3rem">{{ item.strategy_name }}
                <img v-if="item.currency_names.length > 0 " src="@/assets/left_up_trag_orange.png"
                     style="width: 0.5rem;height: 0.5rem">
              </div>
            </div>

            <div class="col">
            <span
                v-if="item.state == '已启动'">{{ getFixed(item.cur_cumulative_revenue + item.earning_revenue, 2) }}</span>
              <span v-else>-</span>
            </div>
            <div class="col">
            <span v-if="item.state == '已启动'"
                  :class="{ 'red-bg': item.cur_cumulative_revenue + item.earning_revenue < 0, 'green-bg': item.cur_cumulative_revenue + item.earning_revenue > 0 }">{{
                getPrecent(item.current_asset ? (item.cur_cumulative_revenue + item.earning_revenue) / item.current_asset : 0, 2)
              }}</span>
              <span v-else>-</span>
            </div>
            <div class="col">
            <span class="label"
                  :class="{ 'red-bg': item.cumulative_revenue_rate < 0, 'green-bg': item.cumulative_revenue_rate > 0 }">
              {{ getPrecent(item.cumulative_revenue_rate, 2) }}
            </span>
              <!--            <span v-else>-</span>-->
            </div>

          </div>
        </div>
      </div>
    </div>
    <VersionNum style="position:absolute;left:0;bottom:0;"></VersionNum>
  </div>

</template>
<script>
import topBlock from "./com/top-block";
import VersionNum from "../com/version_num";
import {getHoldings, setGridOrder} from "@/api/home";
import InfoCommen from "../../mixins/InfoCommen";
import {getGridList} from "../../api/home";
import {Dialog, Notify} from 'vant';

let timer = null
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    topBlock,
    VersionNum,
    [Notify.Component.name]: Notify.Component,
  },
  mixins: [InfoCommen],
  data() {
    return {
      search: "",
      swiper_list: [],
      list: [],
      grid_list: [],
      table_list: [],
      grid_account: [],
      grid_stategy: [],
      grid_item: [],
      source_list: [],
      selectIndex: [0, 0, 0],
      is_qushi: true,
      account_id: '',
      swipe_index: 0
    };
  },
  computed: {
    show_table_list() {
      if (this.search) {
        return this.table_list.filter(item => {
          const title = String(item.name).toLowerCase()
          return title.includes(this.search.toLowerCase())
        })
      } else {
        return this.table_list
      }
    }
  },
  methods: {
    //获取数据
    handlerGetHoldings() {
      //获取趋势策略的数据
      return getHoldings().then((result) => {
        const list = result.data.list;
        this.source_list = list;
        this.list = list.map((item) => {
          // const status_value = item.pos_equity + item.earning_revenue;
          const status_value = item.pos_equity;
          const value1 = status_value - item.init_asset;
          return {
            title: item.ac_nick_name,
            status: value1 >= 0 ? "up" : "down",
            status_value,
            value1: value1,
            value2: item.init_asset ? (status_value / item.init_asset - 1) : 0,
            selected: false,
          };
        });
        //获取网格策略的数据
        getGridList().then(res => {
          if (res.data.length > 0) {
            for (let i in res.data) {
              const grid_info = res.data[i]
              grid_info['strategy_type'] = '网格策略'
              grid_info._id = res.data[i].account_info.account_id
              this.source_list.push(grid_info)
              const status_value = grid_info.account_info.init_asset + grid_info.account_info.cumulative_revenue;
              const value1 = grid_info.account_info.cumulative_revenue;
              const temp_list = {
                title: grid_info.account_info.ac_nick_name,
                status: value1 >= 0 ? "up" : "down",
                status_value,
                value1: value1,
                value2: grid_info.account_info.init_asset ? (status_value / grid_info.account_info.init_asset - 1) : 0,
                selected: false
              };
              //网格的数据追加在趋势后
              this.list.push(temp_list)
            }
          }

          // 排序
          let tmp_list = []
          let tmp_source_list = []
          for (let tmp_index in this.list) {
            if (this.list[tmp_index].title == 'OK-S01') {
              tmp_list.unshift(this.list[tmp_index])
              tmp_source_list.unshift(this.source_list[tmp_index])
            } else if (this.list[tmp_index].title == 'G-OK-S03') {
              tmp_list.splice(1, 0, this.list[tmp_index])
              tmp_source_list.splice(1, 0, this.source_list[tmp_index])
            } else {
              tmp_list.push(this.list[tmp_index])
              tmp_source_list.push(this.source_list[tmp_index])
            }

            // console.log(this.source_list[tmp_index])
            // if (this.source_list[tmp_index].strategy_type === "趋势策略") {
            //   if (this.source_list[tmp_index].position_info.length === 0) {
            //     tmp_list.push(this.list[tmp_index])
            //     tmp_source_list.push(this.source_list[tmp_index])
            //   } else {
            //     let flag = false
            //     for (let i in this.source_list[tmp_index].position_info) {
            //       if (this.source_list[tmp_index].position_info[i].had_position_count > 0) {
            //         tmp_list.unshift(this.list[tmp_index])
            //         tmp_source_list.unshift(this.source_list[tmp_index])
            //         flag = true
            //         break
            //       }
            //     }
            //     if (flag === false) {
            //       tmp_list.push(this.list[tmp_index])
            //       tmp_source_list.push(this.source_list[tmp_index])
            //     }
            //   }
            // }
            // else if (this.source_list[tmp_index].strategy_type === "网格策略") {
            //   if (this.source_list[tmp_index].grid_info.had_start.length === 0) {
            //     tmp_list.push(this.list[tmp_index])
            //     tmp_source_list.push(this.source_list[tmp_index])
            //   } else {
            //     let grid_flag = false
            //     for (let j in this.source_list[tmp_index].grid_info.had_start) {
            //       if (this.source_list[tmp_index].grid_info.had_start[j].currency_names.length > 0) {
            //         tmp_list.unshift(this.list[tmp_index])
            //         tmp_source_list.unshift(this.source_list[tmp_index])
            //         grid_flag = true
            //         break
            //       }
            //     }
            //     if (grid_flag === false) {
            //       tmp_list.push(this.list[tmp_index])
            //       tmp_source_list.push(this.source_list[tmp_index])
            //     }
            //   }
            // }
          }
          this.source_list = tmp_source_list
          this.list = tmp_list


          let swiper_count = 0;
          const swiper_list = [];
          let swiper_child = [];
          for (let i = 0; i < this.list.length; i++) {
            if (swiper_count < 3) {
              swiper_child.push(this.list[i]);
              swiper_count++;
            } else {
              swiper_list.push(swiper_child);
              swiper_child = [this.list[i]];
              swiper_count = 1;
            }
          }
          if (swiper_child.length) {
            swiper_list.push(swiper_child);
          }
          this.swiper_list = swiper_list;
          // console.log(swiper_list)
          // console.log(swiper_child)
          if (this.account_id !== '') {
            for (let i = 0; i < this.source_list.length; i++) {
              // console.log(i)
              if (this.source_list[i]._id == this.account_id) {
                let s_index = i / 3 ^ 0
                let i_index = i % 3
                this.swipe_index = s_index
                this.handlerChangeList(i, s_index, i_index)
              }
            }
            this.account_id = ''
          } else {
            this.handlerChangeList(this.selectIndex[0], this.selectIndex[1], this.selectIndex[2])
          }
        })
      }).catch(() => {
      });
    },
    handlerChangeList(index, s_index, i_index) {
      this.selectIndex = [
        index,
        s_index,
        i_index
      ]
      const info = this.source_list[index];
      this.swiper_list.forEach(child => {
        child.forEach(item => {
          item.selected = false
        })
      })
      // console.log(this.selectIndex)
      this.swiper_list[this.selectIndex[1]][this.selectIndex[2]]['selected'] = true
      if (info.strategy_type !== '网格策略') {
        this.is_qushi = true
        this.table_list = info.position_info.map((item) => {
          const profit = item.earning_revenue;
          return {
            name: item.currency_name,
            sub_name: item.exchange_name,
            new_price: item.ticker_price,
            profit: profit,
            rate: item.pos_equity ? profit / item.pos_equity : 0,
            account_id: info._id,
            trade_type: item.trade_type
          };
        });
      } else {
        this.is_qushi = false
        let gridItem = []
        for (let item in info.grid_info) {
          //对开启的交易币对做格式处理
          if (item === 'no_start') {
            for (let i in info.grid_info[item]) {
              info.grid_info[item][i]['currency_names'] = []
              gridItem.push(info.grid_info[item][i])
            }
          } else {
            for (let i in info.grid_info[item]) {
              // console.log(i)
              gridItem.push(info.grid_info[item][i])
            }
          }

        }
        for (let i in gridItem) {
          gridItem[i].account_id = this.source_list[index].account_info.account_id
        }
        this.grid_item = gridItem
        // console.log(this.grid_item)
      }

    },

    //跳转详情页
    handlerGoDetail(row) {
      // console.log(row)
      this.$router.push({
        path: "/home/postion_info", query: {
          account_id: row.account_id,
          currency_name: row.name,
          trade_type: row.trade_type,
        }
      });
    },

    //跳转网格交易币对详情页
    handlerGridDetail(strategy_id, pos_info, account_id, state) {
      // console.log(state)
      if (state == '已启动') {
        //跳转详情页
        if (pos_info) {
          this.$router.push({
            path: '/home/details/working',
            query: {
              account_id,
              strategy_id,
              pos_info
            }
          })
        }
        //交易币对开仓
        else {
          // Notify({type: 'warning', message: '该策略下没有运行的交易币对！'});
          const beforeClose = (action, done) =>
              new Promise((resolve) => {
                // setTimeout(() => {
                if (action === 'confirm') {
                  setGridOrder(strategy_id).then((res) => {
                    if (res["code"] == 0) {
                      done(false)
                      Notify({type: 'success', message: "开仓成功!"});
                      this.account_id = account_id
                      this.handlerGetHoldings()
                    } else {
                      Notify({type: 'warning', message: res.message});
                    }
                    Dialog.close()
                  })
                  resolve(true);
                } else {
                  // 拦截取消操作
                  Notify({type: 'warning', message: "取消开仓!"});
                  Dialog.close()
                  done(false)
                }
                // }, 1000);
              })
          Dialog.confirm({
            title: '标题',
            message: '该策略下没有运行的交易币对！ \n 是否按照已经生成的交易计划开仓?',
            beforeClose,
          });
        }

      } else {
        // console.log("1!1")
        Notify({type: 'warning', message: state + ',该策略下没有运行的交易比对！'});
      }
    },
  },
  mounted() {
    this.handlerGetHoldings().then(() => {
    });
    timer = setInterval(() => {
      this.handlerGetHoldings()
    }, 10000)
  },
  destoryed() {
    if (timer) {
      clearInterval(timer)
    }
  },
  beforeRouteEnter(to, from, next) {
    // console.log(to,from )
    if (from.path === '/home/details/working' || from.path === '/home/postion_info') {
      next(vm => {
        vm.account_id = from.query.account_id
      })
    }
    next(vm => {
      vm.handlerGetHoldings()
    })
  },

};
</script>

<style lang="less" scoped>

.top-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .top-item {
    width: 33%;
    overflow: hidden;
  }

  .selected {
    //border: 1px dotted #fff;
    background-color: #333;
  }
}

.table {
  text-align: center;
  line-height: 2rem;


  .row {
    display: flex;
    margin: 10px 10px;

    .col {
      width: 22%;
      overflow: hidden;
    }

    .col-1 {
      text-align: left;
      width: 34%;

      .name {
        font-size: 0.8rem;
        line-height: 1.2rem;

      }

      .grid_name {
        font-size: 0.8rem;
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .sub-name {
        color: #8b8a8a;
        font-size: 0.8rem;
        line-height: 1.2rem;
      }

      .dot {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: orange;
        margin-top: 0.8rem;
        margin-left: 0.3rem;
      }
    }

    .label {
      padding: 3px 10px;
    }
  }

  .header {
    color: #4b74ab;
    font-size: 0.8rem;

  }
}

.van-dialog {
  color: #383838 !important;
}
</style>
