import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.less';
import { Form, NavBar,Search, Tabbar, TabbarItem ,Swipe, SwipeItem,Field,Button, Tab, Tabs , Icon ,DropdownMenu, DropdownItem ,Radio,RadioGroup ,Overlay } from "vant";
import { Switch } from 'vant';
import { Divider,List,PullRefresh } from 'vant';
import { Popup,Picker,Cell,CellGroup } from 'vant';

Vue.use(Icon);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Switch);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Button);
Vue.use(DropdownMenu);
Vue.use(DropdownItem)
.use(NavBar)
.use(Swipe)
.use(SwipeItem)
.use(Tabbar)
.use(TabbarItem)
.use(Search)
.use(Field)
.use(Form)
.use(Divider)
.use(Cell)
.use(CellGroup)
.use(Radio)
.use(RadioGroup)
.use(Overlay)



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
