import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/home/over_view.vue'
import Home from '../views/home/index.vue'
import Layout from '@/layout'


Vue.use(VueRouter)

const routes = [
    {
        path: '/loading_page',
        name: '加载中',
        component: () => import ('@/views/loading_page/index')

    },
    {
        path: '/',
        component: Layout,
        redirect: '/loading_page',
        children: [
            {
                path: 'home',
                name: '持仓情况',
                component: Home,
            },
            {
                path: 'notice',
                name: '通知',
                component: () => import ('@/views/notice/index')
            },
            {
                path: 'setting',
                name: '设置',
                component: () => import ('@/views/setting/index')
            },
            {
                path: 'stock',
                name: '持股情况',
                component: () => import ('@/views/stock/index')
            }
        ],
    },
    {
        path:'/some_error',
        name: '错误',
        component: () => import ('@/views/some_error/index')
    },
    {
        path: '/home',
        component: Layout,
        children: [
            {
                path: 'postion_info',
                name: '策略持仓情况',
                component: () => import ('@/views/home/postion-info')
            },
            {
                path: 'postion_set',
                name: '策略持仓设置',
                component: () => import ('@/views/home/postion-set')
            },
            {
                path: 'details',
                name: '策略详情',
                redirect: '/home/details/working',
                component: () => import ('@/views/home/details/index'),
                children: [
                    {
                        path: 'working',
                        name: '正在执行',
                        component: () => import ('@/views/home/details/working')
                    },
                    {
                        path: 'finished',
                        name: '已完成',
                        component: () => import ('@/views/home/details/finished')
                    },
                    {
                        path: 'grid_detail',
                        name: '网格详情',
                        component: () => import ('@/views/home/details/grid_detail')
                    },
                ]
            }
        ]
    },
    {
        path: '/stock',
        component: Layout,
        children: [
            {
                path: 'detail',
                name: '持股情况',
                component: () => import ('@/views/stock/detail')
            },
            {
                path: 'more_history',
                name: '历史记录',
                component: () => import ('@/views/stock/more_history')
            },
        ]
    },
    {
        path: '/setting',
        component: Layout,
        children: [
            {
                path: 'fund_trans',
                name: '资金划转',
                component: () => import ('@/views/setting/fund-trans')
            },
            {
                path: 'fund_unio',
                name: '资金归集',
                component: () => import ('@/views/setting/fund-unio')
            },
        ]
    },
    {path:'/stock_information', name:'股票信息',
    component:()=> import("@/views/com/stock_information")},

    {
        path: '/stock/detail',
        component: Layout,
        children: [
            {
                path: 'more_history',
                name: '历史记录',
                component: () => import ('@/views/stock/more_history')
            },
        ]
    },

    {path: '*', redirect: '/some_error', hidden: true}
]

const router = new VueRouter({
    routes,
    mode: 'history',
    //  路由钩子函数
    // router.
})

export default router
