import Axios from 'axios'
import { Notify } from 'vant';
import store from '../store'
import {getToken} from "./token";
import {login} from "../api/login";



const service = Axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 60000,
})

function removeNull(params) {
    for (const key in params) {
        if (params[key] === '') {
            delete params[key]
        }
    }

}

const white_path = ['/api/v1/user/wxmplogin']

service.interceptors.request.use(async config => {
    if(!white_path.includes(config.url)){
       let token = getToken()
        if(!token){
            await login()
            token = getToken()
        }
        config.headers['Token'] = token
    }
    if(config.method.toLowerCase() === 'get'){
        removeNull(config.params)
    }
    config.headers['Content-Type'] = 'application/json'
    return config
},error => {
    console.log(error)
    return Promise.reject(error)
})

service.interceptors.response.use(response => {
    // eslint-disable-next-line no-debugger
    const res = response.data;
    if(res.code !== 0){
        Notify({ type: 'danger', message: res.message || 'Error' });
        if(res.code === 401){
            store.dispatch('user/resetToken').then(() => {
                location.reload()
            })
        }
        return res
    }else{
        return res
    }
},error => {
    // eslint-disable-next-line no-debugger
    console.log('error',error)
    Notify({ type: 'danger', message: error.message || 'Error' });
    return Promise.reject(error)
})

export default service;
