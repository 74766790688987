import request from '../utils/request'

export function getHoldings(){
    return request({
        method: 'get',
        url: '/api/v1/websocket/holdings',
        params: {

        }
    })
}

export function getHoldingsDetail(params) {
    return request({
        method: 'get',
        url: '/api/v1/websocket/holdings/detail',
        params
    })
}



export function getStrategyInfos(params) {
    return request({
        method: 'get',
        url: '/api/v1/websocket/strategy_infos',
        params,
    })
}

export function pincan(strategy_id,quantity){
    return request({
        method: 'post',
        url: '/api/v1/strategy/sell',
        data: {
            strategy_id,
            quantity
        }
    })
}

// 获取网格信息
export function getGridList(params) {
    return request({
        url: '/api/v1/websocket/grid_info',
        method: 'get',
        params
    })
}
//网格策略详情
export function getGridDetail(strategy_id) {
    return request({
        url: '/api/v1/websocket/grid_detail',
        method: 'get',
        params:{
            strategy_id
        }
    })
}
//网格策略交易对仓位详情
export function getGridDetails(strategy_id,strategy_pos_id) {
    return request({
        url: '/api/v1/websocket/grid_currency',
        method: 'get',
        params:{
            strategy_id,
            strategy_pos_id
        }
    })
}
//停止
export function updateState(params) {
    return request({
        url: '/api/v1/strategy/state',
        method: 'PUT',
        data: params
    })
}

//手动开仓
export function setGridOrder(id) {
    return request({
        url: '/api/v1/grid/order',
        method: 'post',
        data: {
            grid_strategy_id:id
        }
    })
}




