const key = 'cat_token_xxxxx';

export function getToken(){

    //上线
    return sessionStorage.getItem(key)

    //线上测试
    //  return "hS2lyuYVbg7xiii3v4qCaySUdBYCCrfnLq8qEHN06Aro8kmFYOtiZO2l25ECuewS7wNVspPyKJMH8MllXNKanDNNKl0Yyesy9xBJmTaBI2E="
    //  return "RO3PU3Fz8QN3emG8vjOALqYf0V09yz0cOg8QdRTyW/DJzn0A0eq0jQGePvjIi1bPmCKGO+Ml1phTlS5vgQkNFifY2iXr2aERqyc3EFQGZwo="
    // tom
    // return "t1p20vHjsicthMKgp0XRpPhVf87bWmwVJfSkli4r0LllQgrfRD4DTTRJtnLVPwBsuiyDlolH1TM4E1hhgocl3/hsbSOMEAKGp5KrABKrIto="
    // return "-1"
}


export function setToken(value){
    return sessionStorage.setItem(key,value)
}
