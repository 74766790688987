<template>
  <div style="width: 100%;text-align: center;color: #8b8a8a;font-size: 15px">
    版本编号: OCAT-V1.8
  </div>
</template>

<script>
export default {
  name: "version_num"
}
</script>

<style scoped>

</style>