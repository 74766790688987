<template>
  <div>
    <!--    <div v-show="empty_flag" class="empty_page">-->
    <!--      <div class="font_sy">-->
    <!--        loading!!!-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="layout" v-show="show_flag" >
      <!--     <van-nav-bar :title="title" left-arrow fixed placeholder safe-area-inset-top />-->
      <section class="app-main" >
        <transition name="fade-transform" mode="out-in">
          <van-pull-refresh v-model="page_loading" @refresh="onRefresh">
            <router-view v-if="is_reload" :key="key" />
          </van-pull-refresh>
        </transition>

      </section>

      <van-tabbar v-if="switch_route" route>
        <van-tabbar-item replace to="/home" icon="after-sale">数字货币</van-tabbar-item>
        <van-tabbar-item replace to="/stock" icon="chart-trending-o">A股</van-tabbar-item>
        <van-tabbar-item replace to="/notice" icon="todo-list-o">通知</van-tabbar-item>
        <van-tabbar-item replace to="/setting" icon="setting-o">设置</van-tabbar-item>
      </van-tabbar>

    </div>
  </div>

</template>
<script>
// import Vue from "vue";
// import {login} from "../api/login";
// import {getToken} from "../utils/token";

export default {
  name: "layout",
  data() {
    return {
      page_loading: false,
      is_reload: true,
      show_flag: true
    }
  },
  computed: {
    key() {
      return this.$route.path;
    },
    title() {
      return this.$route.name;
    },
    switch_route() {
      return ["/home", "/notice", "/setting", '/stock'].includes(this.$route.path);
    },
  },
  watch: {
    "$route.path": function () {
      window.document.title = this.$route.name;
    },
  },
  created() {

  },
  mounted() {
    // console.log(this.$route);
    window.document.title = this.$route.name;
  },
  methods: {
    onRefresh() {
      this.is_reload = false
      this.$nextTick(() => {
        this.is_reload = true
        this.page_loading = false
      })

    }
  }
};
</script>

<style>

</style>
