export default {
    methods: {
        getColor(value) {
            if (value > 0) {
                return '#4bba41'
            } else if (value < 0) {
                return '#e45353'
            }
        },

        getStockColor(value) {
            if (value < 0) {
                return '#4bba41'
            } else if (value > 0) {
                return '#e45353'
            }
        },
        getFixed(value, fix) {
            if (Number(value) === Number.NaN) {
                return ''
            }
            return Number(value).toFixed(fix)
        },

        getPrecent(value, fix) {
            return this.getSymbolString(value * 100, fix) + '%'
        },

        getPrecentWith0(value1, value2, fix) {
            if (value2 === 0) {
                return '0.0%'
            } else {
                return this.getSymbolString(value1 / value2 * 100, fix) + '%'
            }
        },

        getSymbolString(value, fix) {
            if (Number(value) === Number.NaN) {
                return ''
            }
            let char = value > 0 ? '+' : ''
            if (value == 0) {
                char = ''
            }
            return char + this.getFixed(value, fix)
        }

    }
}
